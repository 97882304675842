// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import YouTubeLink from 'components/Contentful/YouTubeLink'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Referenzgeschichte Daniel Bieri"
        meta={[
          { name: 'description', content: 'Sole/Wasser-Wärmepumpe anstatt einer Gasheizung ➤ Erfahrungsbericht von Daniel Bieri ✔️ Erdsondenbohrung ✔️ Umbau ✔️ Hier informieren!' },
          { name: 'keywords', content: 'Video Erfahrungsbericht Erdsondenbohrung Sole/Wasser-Wärmepumpe' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='2FOcR9GvhqRgNN45RLgxMm-4F914UOIMglt0IqTFAQayK'
          id='video-referenz-story-daniel-bieri-oberdorf-so'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "4F914UOIMglt0IqTFAQayK",
    "updatedAt": "2022-10-28T08:18:54.492Z",
    "title": "Referenz-Video Erdsondenbohrung: Daniel Bieri, Oberdorf SO",
    "size": "Groß",
    "teaser": "Daniel Bieri aus Oberdorf SO beschloss, seine alte, 20-jährige Gasheizung durch eine Sole/Wasser-Wärmepumpe zu ersetzen. Wieso der ausgebildete Hochbautechniker, welcher beruflich auch mit Erdsondenbohrungen zu tun hat, sich ausgerechnet für die Heizungsmacher AG entschieden hat und weshalb er auf Erdwärme setzen möchte, erzählt er in diesem Referenz-Video. ",
    "image": {
        "description": "Drohnenaufnahme Familie Bieri Projekt Erdsondenbohrung",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/4h7LzyOMHmfwBWxMLHIntI/a496814d934ad1e363ebd68ee5ebe4ca/Daniel-Bieri-Titelbild-Hero-gross.png"
    },
    "links": [
        {
            "type": "link",
            "id": "2HoGCnl720mMqmQ108H1F7",
            "updatedAt": "2023-07-25T06:25:06.308Z",
            "label": "Zur Referenz-Übersicht",
            "url": "https://www.heizungsmacher.ch/referenzen/"
        }
    ],
    "anchorName": "video-referenz-story-daniel-bieri-oberdorf-so"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='2FOcR9GvhqRgNN45RLgxMm-e1R8IeN0cvL3YHOhqABx2'
          id=''
        >
          <YouTubeLink
            {...{
    "type": "youTubeLink",
    "id": "e1R8IeN0cvL3YHOhqABx2",
    "updatedAt": "2022-10-28T08:25:29.299Z",
    "title": "Referenz-Video Daniel Bieri, Oberdorf SO",
    "link": "https://www.youtube.com/watch?v=eIapWRtVB6M",
    "description": "<p>Die Erdsondenbohrung - Ein Referenzvideo mit Daniel Bieri. Der Hauseigentümer Daniel Bieri erzählt in diesem Video, wie er die Zusammenarbeit mit den Heizungsmachern erlebt hat, wie er die Arbeiten wahrgenommen hat und welche Schlussbilanz er aus der Zusammenarbeit mit den Heizungsmachern zieht.</p>\n",
    "videoId": "eIapWRtVB6M",
    "start": null
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='2FOcR9GvhqRgNN45RLgxMm-49AEAcerP63AFFeU2JOFw0'
          id='haben-sie-eine-erdsondenbohrung-nah-miterlebt-daniel-bieri-erzaehlt'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "49AEAcerP63AFFeU2JOFw0",
    "updatedAt": "2022-10-28T08:25:55.286Z",
    "title": "Haben Sie eine Erdsondenbohrung nah miterlebt? Daniel Bieri erzählt:",
    "anchorName": "haben-sie-eine-erdsondenbohrung-nah-miterlebt-daniel-bieri-erzaehlt",
    "text": "<p>Am Jurasüdfuss, oberhalb vom Kanton Solothurn, wohnt Daniel Bieri in seinem 20-jährigen Einfamilienhaus. Die bestehende <a href=\"https://www.heizungsmacher.ch/wissen/gasheizung-ersetzen-kosten-foerderungen-und-regelungen-in-der-schweiz/\">Gasheizung</a> kam allmählich an die Grenze ihrer Lebensdauer – <strong>so hat sich der Hochbautechniker entschieden, die fossile Heizung durch eine Sole/Wasser-Wärmepumpe ersetzen zu lassen</strong>.</p>\n<p>«Die Erdsonde ist die nachhaltigste Lösung, weil der Fremdenergiebedarf am kleinsten ist. Die Investition wird sich über die Lebensdauer der Anlage rechtfertigen.» erklärt er im Interview und fährt fort: «<strong>Wir haben uns für die Firma Heizungsmacher entschieden, weil sie eine Unternehmung ist, welche mit regionalen Subunternehmen arbeitet und das Gesamt-Paket abdeckt und das Angebot auch wirtschaftlich interessant gewesen ist</strong>.»</p>\n<p>Dank des Förderprogramms fliessen rund <strong>10’000 CHF Fördergelder</strong> zurück.</p>\n<p>Was Sie alles über den Prozessablauf und das einzigartige Geschäftsmodell der Heizungsmacher wissen müssen, erklärt Ihnen Thomas Schweingruber von der Heizungsmacher in diesem Video in wenigen Worten.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='2FOcR9GvhqRgNN45RLgxMm-5jl6yjDwGax05p7DpzlEBw'
          id='wuenschen-sie-eine-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5jl6yjDwGax05p7DpzlEBw",
    "updatedAt": "2023-08-30T09:41:16.098Z",
    "title": "Wünschen Sie eine Beratung?",
    "anchorName": "wuenschen-sie-eine-beratung",
    "text": "<p>Sie interessieren sich für eine Wärmepumpe und sind noch nicht sicher, ob dies die richtige Wahl ist? Besuchen Sie unseren kostenlosen Heizungs-Check oder lassen Sie sich von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\" title=\"ueber-uns\">Heizexperten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
